html {
  font-size: 3vmin;
}

body {
  background-color: #ffd6ef;
}

button,
input {
  font-size: 1rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.bath-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 4rem;
  }

  p {
    font-size: 1.5rem;
  }

  button,
  input {
    font-size: 1.25rem;
  }

  button {
    width: 6.5rem;
  }
}

h1 {
  margin-top: 0;
  text-align: center;
}

.tag-text {
  text-align: center;
}

.loading-bath-container {
  text-align: center;
}

.tub-container {
  width: fit-content;
  overflow: hidden;
  /*height: 35rem;*/
}

.tub {
  width: 30rem;
  height: 12rem;
  border-radius: 0% 0% 100% 100%;
  border-left: solid 0.75rem white;
  border-right: solid 0.75rem white;
  border-bottom: solid 0.75rem white;
}

.taps-container {
  display: flex;
  height: 3rem;
}

.drain {
  height: 10rem;
  width: 1rem;
  margin-left: 15rem;
  margin-top: -2.5rem;
}

.stream {
  height: 10rem;
  width: 1rem;
  position: absolute;
}

.cold {
  background-image: linear-gradient(rgb(109, 216, 255), rgba(0, 0, 0, 0));
  margin-left: 2rem;
}

.hot {
  background-image: linear-gradient(rgb(200, 241, 255), rgba(0, 0, 0, 0));
  margin-left: 8rem;
}

.overflow-left {
  margin-left: -1rem;
}

.overflow-right {
  margin-left: 31.5rem;
}

.new-bath-menu-button {
  width: fit-content;
}

.new-bath-menu-container {
  position: absolute;
  margin-left: -7rem;
  margin-top: -2rem;
  width: fit-content;
  width: 20rem;
  background-color: #ffd6ef;
  text-align: center;
  border: solid 0.5rem white;
  border-radius: 20%;
  padding: 1rem 0rem;
}

.new-bath-menu-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0rem 1rem;
}

.overflow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0.8;
}

.settings-container {
  position: absolute;
  top: 0;
  right: 0;
}

.high-contrast {
  width: fit-content;
}
